//
// Full build of bootstrap with Studyladder theme/customizations
//
// NOTE: To access bootstraps variables/functions/mixings use:
//
// @import "application/views/scripts/scss/studyladder-theme"
//
// Do not import this file as it includes the entire bootstrap css.
//
@import "studyladder-theme";
@import "node_modules/bootstrap/scss/bootstrap";

//
// .modal-xl
//
// "Extra-large" modal, set to the width of the underlying page content
//
@each $name, $value in $container-max-widths {
  @include media-breakpoint-up($name) {
    .modal-xl { max-width: $value; }
  }
}



/**********************************************************************
 * style rules to be sorted and cleaned up below
 **********************************************************************/
/** *****************************************************************************************************
  * Typography - overrides
  ******************************************************************************************************/

  @include media-breakpoint-up(md) {
    body {
      h1, .h1 {
        font-size: 2.5rem;
      }
      h2, .h2 {
        font-size: 1.875rem;
      }
      h3, .h3 {
        font-size: 1.5rem;
      }
      h4, .h4 {
        font-size: 1.25rem;
      }
      h5, .h5 {
        font-size: 1.125rem;
      }
      p {
      //line-height: 1.75rem !important;
    }
  }
}

body {
  h5, .h5 {
    font-weight: 500;
  }
  .btn {
   font-weight: 500;
 }
}

//checklist styled with fontawesome ticks
.check-list {
  list-style: none;
  padding-left: 0;

  li {
    margin-left: 2rem;
  }

  li::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    content: "\f00c";
    margin-right: 1rem;
    margin-left: -2rem;
    color: $green;
  }
}

/**************************************************************************************************************************
 * Header Styling
 **************************************************************************************************************************/
nav.navbar {
  padding: .15rem 1rem;

  .form-inline {
    font-size: 0.75rem;
    .label, .lc_select{
      font-size: inherit;
    }
    .form-control-sm {
      height: calc(1.3375rem + 2px);
      padding: 0.15rem 0.2rem;
      font-size: .75rem;
      line-height: 1.1;
    }
  }
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0,0,0,.7) !important;
}

.fa-sm {
  font-size: 1.3rem !important;
}

.banner .page-title {
  $trans-grey1: rgba(52, 58, 64, 0.0);
  $trans-grey2: rgba(52, 58, 64, 0.65);
  //@include gradient-y($trans-grey1, $trans-grey2);
  @include gradient-y-three-colors($trans-grey1, $trans-grey2, 85%, $trans-grey2);
}

main {
  //fixes IE11 not supporting <main> elements
  display: block;
}

/** ******************************************************************************************************************************
  * Styling from public.scss
  ***************************************************************************************************************************** */
//.bg-lightgrey {background-color:#eee; }
//.bg-darkgrey {background-color:#bbb; }

//is there a better way to make these in the theme but have specific hover states rather than the generic ones?
.btn-light {color: #627999;background-color: #F5F5F7;border-color: #627999;}
.btn-light:hover {border-color: #627999;}

.btn-blue {color: #0e1924;background-color: #D8E0EA;border-color: #0e1924;}
.btn-blue:hover {background-color: #e2e6ea;}

.btn-icon {border-radius: 20px;}

.dark-links {
  a {color: #212529;}
  a:hover {color: $primary;text-decoration: none;}
}

.general-copy {
  h2, h3 {
    margin-bottom:1rem !important;margin-top:2.5rem !important;
  }
}

.banner h1 {
  margin-bottom: 0.2em;
}


.w-60 {width:60% !important;}
.w-40 {width:40% !important;}
  header.site-header-container {
    min-height: 58px !important;
    }
@include media-breakpoint-down(md) {

  header.site-header-container {
    min-height: 58px !important;

    &>.site-header {
      min-height: 50px !important;
      .navbar-expand-lg .container {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }
    }
    .navbar-dark {
     // display: none;
    }
  }

  .bg-image.banner {
    min-height:300px;
  }

}

/*****************************************************************************************************************************
* global page styling
*****************************************************************************************************************************/

@include media-breakpoint-down(md) {
  div.section {
    padding-top: 2.5em;
  }
}

@include media-breakpoint-down(sm) {
  .container {
    padding-left:2rem; padding-right:2rem;
  }
  .container-narrow {
    padding-left:1.5rem; padding-right:1.5rem;
  }
  //footer
  .copyright, .legal {
    width:100%;
  }
}

/* brand logo styling */
a.navbar-brand {
  //font-size: 2.15em;
  //margin-top: -0.5rem;
  //margin-bottom: -0.5rem;
  max-width: 170px;
  img {
    width:100%; height:auto;
  }
}
a.navbar-brand,
a.navbar-brand em {
 font-family:'Times New Roman';
}
a.navbar-brand em {
 color:#29ABE2;
}
.site-header .logo-sl {
  max-width:50px;

}

/* brand logo styling  - interrim header*/
.logo {
 font-size: 1.8em;
}
.logo,
.logo em {
  font-family:'Times New Roman';
}
.logo em {
  color:#29ABE2;
}
nav.bg-brand {
  font-size: 0.85em;
}

/* breadcrumb element overrides */
.breadcrumb {
  background-color: transparent !important;

  .breadcrumb-item {
    font-size: 0.875rem;

    a,
    &.active,
    &+.breadcrumb-item::before {
     // color: #f8f9fa; /* text-light colour value */
    }
  }
}

/* end of theme overrides */

div.section {
  padding-top: 1em; padding-bottom: 1em;
}
footer.footer {
  padding-top: 1em; padding-bottom: 1em; margin-top: 1em;
  .logo {
    img {
      width:100%; height:auto;max-width:200px;
    }
  }
}


/* section padding for larger screens */
@media (min-width: 992px) {
  div.section {
    padding-top: 5em; padding-bottom: 5em;
  }
  footer.footer {
    padding-top: 5em; margin-top: 5em;
  }
}

.bg-image.banner {
  min-height: 25vw;
}


.jumbotron .card .btn {
  border-radius: 0rem!important;
  border-bottom-right-radius: .25rem!important;
  border-bottom-left-radius: .25rem!important;
}

.blockquote footer {
  color: #634889; font-weight: 500;
}
